import * as React from 'react'
import { globalHistory } from '@reach/router'

const FBComments = () => {
    const [loaded, setLoaded] = React.useState(false)

    const appendSdkScript = () => {
        const script = document.createElement('script')
        script.id = 'fb-sdk'
        script.src = `https://connect.facebook.net/${process.env.GATSBY_SITE_LANG}/sdk.js`
        script.async = true
        script.defer = true
        script.crossOrigin = 'anonymous'
        script.onload = () => setLoaded(true)
        document.body.append(script)
    }

    const scriptAlreadyExists = () =>
        document.querySelector('script#fb-sdk') !== null

    React.useEffect(() => {
        if (!scriptAlreadyExists()) {
            appendSdkScript()
        }
    }, [])

    React.useEffect(() => {
        if (!loaded) return

        window.FB.init({
            appId: process.env.GATSBY_FB_APP_ID,
            xfbml: true,
            version: 'v11.0'
        })
    }, [loaded])

    const url = globalHistory.location.origin
        ? globalHistory.location.origin.replace(/^https?:\/\//, '')
        : globalHistory.location.origin

    return (
        <div
            className="fb-comments"
            data-href={url}
            data-width="100%"
            data-numposts="5"
            data-lazy="true"
        />
    )
}

export default FBComments
