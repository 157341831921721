import * as React from 'react'

export default function CircleIcon({ className, width = 16 }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width={width}
            fill="currentColor"
            viewBox="0 0 16 16"
        >
            <circle cx="8" cy="8" r="8" />
        </svg>
    )
}
