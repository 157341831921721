import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { FastForwardIcon, PlayIcon, VolumeUpIcon } from '@heroicons/react/solid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useSWRImmutable from 'swr/immutable'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import SwipeStream from '../components/SwipeStream'
import AdSlot from '../components/AdSlot'
import FBComments from '../components/FbComments'
import CurrentProgram from '../components/channel/CurrentProgram'
import CurrentPrograms from '../components/CurrentPrograms'

const Stream = ({ data, location }) => {
    const { uid } = data.prismicChannel
    const doc = data.prismicChannel?.data
    const settings = data.prismicGeneralSettings?.data

    const image = getImage(doc.image?.localFile)

    const hasPrograms = data.swapi?.channels
        ?.map(c => c.publisher_slug)
        .includes(uid)

    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data: apiData } = useSWRImmutable(
        hasPrograms
            ? `${process.env.GATSBY_API_URL}/channels/${uid}/programs/now`
            : null,
        fetcher
    )

    const handleClick = () => {
        window.open(
            doc.live_channel_desktop_link?.url,
            'newWin',
            'toolbar=0, location=0, directories=0, status=0, menubar=0, scrollbars=1, resizable=0, width=1200, height=665'
        )
    }

    if (!data) return null

    return (
        <Layout spacedFooter>
            <Seo
                title={
                    doc.live_stream_seo_title?.text ||
                    doc.live_stream_title?.text ||
                    `${doc.name?.text}: regarder le direct sur ordinateur gratuitement`
                }
                description={
                    doc.live_stream_seo_description?.text ||
                    `Regarder votre chaîne de TV préférée en direct sur votre ordinateur, tablette ou smartphone. Suivez le live sur internet maintenant: ${doc.name?.text}`
                }
                ogImage={doc.live_stream_og_image?.url}
            />
            <div className="mx-auto px-6 pt-5 pb-20 max-w-3xl">
                {/* Live Channel Player Top Slot */}
                <AdSlot html={settings.live_channel_player_top_slot?.text} />

                <h1 className="text-3xl font-light text-gray-700 leading-snug">
                    {doc.live_stream_title?.text ||
                        `${doc.name?.text}: regarder le direct sur ordinateur gratuitement`}
                </h1>

                <div
                    className="group relative flex flex-col items-center justify-center mt-4 mb-4 w-full h-60 sm:h-72 lg:h-96 bg-black appearance-none focus:outline-none"
                    onClick={handleClick}
                    title={`Regarder ${doc.name?.text} en direct`}
                >
                    <div className="absolute top-4 left-4 flex items-center gap-4">
                        <GatsbyImage image={image} alt={doc.name?.text} />
                        <CurrentProgram
                            className="text-white"
                            data={apiData}
                            enabled={hasPrograms}
                        />
                    </div>

                    <PlayIcon className="w-20 sm:w-24 md:w-32 text-white opacity-50 group-hover:opacity-75 transition" />
                    <div className="absolute left-0 bottom-0 flex items-center gap-4 py-4 px-6 text-white">
                        <PlayIcon className="w-6" />
                        <FastForwardIcon className="w-6" />
                        <VolumeUpIcon className="w-6" />
                    </div>
                </div>

                {/* Live Channel Player After Player Slot */}
                <AdSlot
                    html={settings.live_channel_player_after_player_slot?.text}
                />

                <CurrentPrograms
                    data={apiData}
                    channel_name={doc?.name?.text}
                    channel_slug={uid}
                    enabled={hasPrograms}
                    partial={true}
                />

                <div className="mt-4 pb-10 prose sm:prose-lg max-w-none text-gray-600">
                    <p className="pb-6 text-center border-b">
                        Si vous rencontrez des difficultés, lisez le{' '}
                        <Link
                            className="underline hover:text-primary"
                            to="/manuel"
                        >
                            manuel d'utilisation
                        </Link>
                    </p>

                    <PrismicRichText
                        field={doc.live_stream_content?.richText}
                        //serializeHyperlink={PrismicLink}
                    />
                </div>

                <FBComments location={location} />
            </div>

            <SwipeStream channels={data.channels.nodes} />
        </Layout>
    )
}

export default Stream

export const query = graphql`
    query StreamQuery($uid: String!) {
        prismicGeneralSettings {
            data {
                live_channel_player_top_slot {
                    text
                }
                live_channel_player_after_player_slot {
                    text
                }
            }
        }
        prismicChannel(uid: { eq: $uid }) {
            uid
            data {
                live_channel_title {
                    richText
                }
                live_channel_content {
                    richText
                }
                live_stream_title {
                    text
                }
                live_stream_content {
                    richText
                }
                live_stream_seo_title {
                    text
                }
                live_stream_seo_description {
                    text
                }
                live_stream_og_image {
                    url
                }
                live_channel_desktop_link {
                    url
                }
                name {
                    text
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                quality: 100
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
        channels: allPrismicChannel {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 80
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
        swapi {
            channels {
                publisher_slug
            }
        }
    }
`
